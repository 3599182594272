<template>
  <!--  轮播图页面-->
  <div class="home-carousel">
    <el-carousel height="555px" :autoplay="true" arrow="always">
      <el-carousel-item v-for="(item,index) in rotationList" :key="index">
        <router-link :to="{name:'rotationDetail', query: {id:item.id}}" target="_blank">
        <div class="content">
          <image-none-preview :src="item.cover" :width="990" :height="560"/>
          <span>{{ item.title }}</span>
        </div>
        </router-link>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { listRotation } from '@/api/rotation/rotation'
import { cutString } from '../../../utils/ruoyi'
import ImageNonePreview from '@/components/ImagePreview/ImageNonePreview.vue'

export default {
  name: 'CarouselItem',
  components: { ImageNonePreview },

  data () {
    return {
      // 遮罩层
      loading: true,

      // 轮播图表格数据
      rotationList: [],
      queryParams: {
        online: 1,
      },
    }
  },
  created () {
    this.getList()
  },
  methods: {
    cutString,
    /** 查询轮播图列表 */
    getList () {
      this.loading = true
      listRotation(this.queryParams).then(response => {
        this.rotationList = response.rows
        this.total = response.total
        this.loading = false
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.home-carousel {
  width: 991px;
}

::v-deep .el-carousel__button {
  height: 6px;
  width: 50px;
}

::v-deep .el-carousel__arrow {
  height: 97px;
  width: 55px;
  border-radius: 0%;
  color: #FFF;
  text-align: center;
  font-size: 32px;
}

::v-deep .el-carousel__arrow--left {
  left: 0;
}

::v-deep .el-carousel__arrow--right {
  right: 0;
}

.content {
  position: relative;

  span {
    display: block;
    height: 63px;
    width: 100%;
    position: absolute;
    display: inline-block;
    padding-left: 20px;
    line-height: 63px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
    bottom: 0px;
    color: #fff;
    left: 0px;
    font-size: 22px;
    letter-spacing: 1.5px;
    font-family: sans-serif;
  }
}
</style>
