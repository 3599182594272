<template>
  <div class="nav-container">
    <el-menu default-active="/home" class="el-menu-nav" mode="horizontal" @select="handleSelect"
             text-color="#fff" background-color="#355e92" active-text-color="#ffd04b" router>

      <el-menu-item index="/home" route="/home"><i class="el-icon el-icon-s-home"></i>首页</el-menu-item>
      <el-menu-item index="/consulting" route="/consulting/lastnews"><i class="el-icon el-icon-news"></i>资讯中心
      </el-menu-item>
      <el-menu-item index="/school" route="/school/psychometrics"><i class="el-icon el-icon-school"></i>学校
      </el-menu-item>
      <el-menu-item index="/teacher" route="/teacher/courseTeaching"><i class="el-icon el-icon-user"></i>教师
      </el-menu-item>
      <el-menu-item index="/parent" route="/parent/parentCourse"><i class="el-icon el-icon-s-custom"></i>家长
      </el-menu-item>

    </el-menu>
    <div class="line"></div>
  </div>

  <!--  //导航不加二级菜单-->
  <!--  <div class="nav-container">-->
  <!--    <el-menu :default-active="$router.path" class="el-menu-nav" mode="horizontal" @select="handleSelect"-->
  <!--             text-color="#fff" background-color="#355e92" active-text-color="#ffd04b">-->

  <!--      <el-menu-item index="/home" route="/home"><i class="el-icon el-icon-s-home"></i>首页</el-menu-item>-->
  <!--      &lt;!&ndash;咨询中心&ndash;&gt;-->
  <!--      <el-submenu index="/consulting">-->
  <!--        <template slot="title">-->
  <!--          <i class="el-icon el-icon-news"></i><span @click="goto('/consulting/lastnews')">资讯中心</span>-->
  <!--        </template>-->
  <!--        &lt;!&ndash;        <el-menu-item index="/consulting/lastnews">最新资讯</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/consulting/notice">公告通知</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/consulting/policy">政策文件</el-menu-item>&ndash;&gt;-->
  <!--      </el-submenu>-->
  <!--      &lt;!&ndash;学校&ndash;&gt;-->
  <!--      <el-submenu index="2">-->
  <!--        <template slot="title"><i class="el-icon el-icon-school"/>学校</template>-->
  <!--        &lt;!&ndash;        <el-menu-item index="/school/psychometrics">心理测评</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/school/attire">心理设备</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/school/sepcialSchool">特色学校</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/school/textBook">教材教参</el-menu-item>&ndash;&gt;-->
  <!--      </el-submenu>-->

  <!--      &lt;!&ndash;教师&ndash;&gt;-->
  <!--      <el-submenu index="3">-->
  <!--        <template slot="title"><i class="el-icon el-icon-user"/>教师</template>-->
  <!--        &lt;!&ndash;        <el-menu-item index="/teacher/courseTeaching">课程教学</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/teacher/actives">活动案例</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/teacher/reading">教师阅读</el-menu-item>&ndash;&gt;-->
  <!--      </el-submenu>-->
  <!--      &lt;!&ndash;家长&ndash;&gt;-->
  <!--      <el-submenu index="parent">-->
  <!--        <template slot="title"><i class="el-icon el-icon-s-custom"/>家长</template>-->
  <!--        &lt;!&ndash;        <el-menu-item index="/parent/parentCourse">家长课堂</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/parent/parentRead">家长阅读</el-menu-item>&ndash;&gt;-->
  <!--        &lt;!&ndash;        <el-menu-item index="/parent/contact">咨询热线</el-menu-item>&ndash;&gt;-->
  <!--      </el-submenu>-->
  <!--      &lt;!&ndash;      <el-menu-item index="5"><i class="el-icon el-icon-chat-dot-round"/>心灵树洞</el-menu-item>&ndash;&gt;-->
  <!--    </el-menu>-->
  <!--    <div class="line"></div>-->
  <!--  </div>-->
</template>

<script>

export default {
  name: 'NavItem',
  data () {
    return {}
  },
  methods: {
    handleSelect (key, keyPath) {
      // console.log(key, keyPath)
      // this.$router.push({
      //   path: key,
      //   query: keyPath
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-container {
  .el-menu-nav {
    display: flex;
    justify-content: space-between;
    height: 70px;
  }

  .el-icon {
    font-size: 22px;
    margin-right: 10px;
    color: white;
  }
}

</style>
