import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/element-ui'
import '@/styles/index.scss'
import plugins from './plugins' // plugins
import ImagePreview from "@/components/ImagePreview";
import { download } from '@/utils/request'
import { parseTime,cutString,selectDictLabel } from "@/utils/ruoyi";
// 分页组件
import Pagination from "@/components/Pagination";
// 字典数据组件
import DictData from '@/components/DictData'

// 字典标签组件
import DictTag from '@/components/DictTag'

// 全局方法挂载

Vue.prototype.parseTime = parseTime
Vue.prototype.cutString = cutString
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.download = download



// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('ImagePreview', ImagePreview)
Vue.component('DictTag', DictTag)

DictData.install()
Vue.config.productionTip = false

Vue.use(plugins)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
