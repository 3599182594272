<template>
  <div class="attire  list-right">
    <div class="bread">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>特色学校</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="schoolList">
      <div class="item" v-for="(item,index) in schoolList"  :key="item.id">
        <div class="item-box">
          <router-link :to="{name:'schoolDetail', query: {id:item.id}}" target="_blank">
          <div class="item-img">
            <image-none-preview :src="item.coverUrl" :width="382" :height="282"></image-none-preview>
          </div>
            <div class="item-info">
              <div>{{ item.schoolName }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="page">
      <pagination
                  v-show="total>0"
                  :total="total"
                  :page.sync="queryParams.pageNum"
                  :limit.sync="queryParams.pageSize"
                  @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import {listSpecial} from '@/api/school/special/special'
import ImageNonePreview from '@/components/ImagePreview/ImageNonePreview.vue'

export default {
  name: 'SpecialSchoolPageList',
  components: { ImageNonePreview },
  data () {
    return {
      // 总条数
      total: 0,
      schoolList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 5,
      },
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      listSpecial(this.queryParams).then(response => {
        this.schoolList = response.rows
        this.total = response.total
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.schoolList {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .item {
    position: relative;
    float: left;
    width: 382px;
    height: 328px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .item-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 6px;
  }


  .item-info {
    position: relative;
    font-size: 20px;
    z-index: 1;
    color: #696E81;
    text-align: center;
    margin-top: 10px;
  }
}
.page {
  text-align: center;
  margin-top: 30px;

  ::v-deep .number.active {
    background-color: #337ab7 !important;
  }
}
</style>
