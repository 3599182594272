<template>
  <div>
    <el-dialog title="网站介绍" :visible="webIntroduceVisible" width="60%" @close="close" :center="true">
      <div class="play">
        <video width="100%" height="100%" controls controlslist="nodownload">
          <source src="http://218.28.225.151:9000/psychology/project/%E5%BF%83%E7%90%86%E6%B5%8B%E8%AF%84%E5%AE%A3%E4%BC%A0%E7%89%87.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="content">

      </div>
      <div class="button"> <el-button type="primary" @click="close">关闭</el-button></div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'WebIntroducePage',
  data () {
    return {}
  },
  props: {
    webIntroduceVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('update:webIntroduceVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  text-align: left;
  p{
    font-size: 18px;
    font-weight: 400;
    margin-top: 12px;
  }
  .contact{
    text-align: center;
  }
}

div.button{
  text-align: center;
  margin-top: 20px;
}
 ::v-deep .el-dialog__title{
   font-size: 25px;
  font-weight: 700;
}
</style>
