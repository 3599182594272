<template>
  <div class="app-container type-area">
    <div class="aside">

      <el-menu
        default-active="/school/psychometrics" background-color="#ffffff" router>
        <el-menu-item index="/school/psychometrics">
          <span slot="title" >心理测评</span>
        </el-menu-item>
        <el-menu-item index="/school/attire">
          <span slot="title">心理装备</span>
        </el-menu-item>
<!--        <el-menu-item index="/school/sepcialSchool">-->
<!--          <span slot="title">特色学校</span>-->
<!--        </el-menu-item>-->
        <el-menu-item index="/school/experienceSchool">
          <span slot="title">学校经验交流</span>
        </el-menu-item>
        <el-menu-item index="/school/textBook">
          <span slot="title">教材教参</span>
        </el-menu-item>
      </el-menu>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'SchoolLayout'
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
}
</style>
