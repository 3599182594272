<!--教师，家长，文章阅读-->
<template>
  <div class="center">
    <el-tabs v-model="activeName" >
      <el-tab-pane label="教师阅读" name="first" lazy>
        <div class="news">
        <teacher-article-item></teacher-article-item>
        </div>
      </el-tab-pane>
      <el-tab-pane label="家长阅读" name="second" lazy>
        <div class="notice">
        <parent-article-item></parent-article-item>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LastNewsItem from '@/views/home/components/consulute/LastNewsItem.vue'
import PolicyItem from '@/views/home/components/consulute/PolicyItem.vue'
import NoticeItem from '@/views/home/components/consulute/NoticeItem.vue'
import TeacherArticleItem from '@/views/home/components/article/TeacherArticleItem.vue'
import ParentArticleItem from '@/views/home/components/article/ParentArticleItem.vue'

export default {
  name: 'ArticleCenter',
  components: { ParentArticleItem, TeacherArticleItem },
  data () {
    return {
      activeName: 'first'
    }
  }
}
</script>

<style lang="scss" scoped>
.center {
  width: 556px;
  height: 556px;

  ::v-deep .el-tabs__item {
    font-size: 24px;
    font-weight: 600;
    height: 51px;
    margin-bottom: 8px;

  }

  ::v-deep .el-tabs__item.is-active {
    color: #0962be;
  }

  ::v-deep .el-tabs__nav-wrap::after {
    width: 70%;
    height: 4px;
    background-color: #E4E7ED;
    z-index: 1;
  }

  ::v-deep .el-tabs__active-bar {
    height: 4px;
    background-color: #0962be;
  }

}
</style>
