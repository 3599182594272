
const parentRouter = {
  path: 'parent',
  name: '家长',
  component: () => import('@/views/parent/index.vue'),
  redirect: '/parent/parentCourse',
  children: [{
    path: 'parentCourse',
    name: 'parentCourse',
    component: () => import('@/views/parent/page/ParentCoursePage.vue'),
  },
  {
    path: 'parentRead',
    name: 'parentRead',
    component: () => import('@/views/parent/page/ParentReadingPage.vue'),
  },
  {
    path: 'contact',
    name: 'contact',
    component: () => import('@/views/parent/page/ContactWePage.vue'),
  }]
}
export default parentRouter
