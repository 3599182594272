<template>
  <div class="textBook list-right">
    <el-tabs type="border-card">
      <el-tab-pane lazy label="中小学心理健康教育教材">
        <TextBookCard></TextBookCard>
      </el-tab-pane>
      <el-tab-pane lazy label="心理教育教学参考">
        <TeachingReferenceCard></TeachingReferenceCard>
      </el-tab-pane>
      <el-tab-pane lazy label="《中小学心理健康教育》杂志">
        <JournalPsychology></JournalPsychology>
      </el-tab-pane>
      <el-tab-pane lazy label="中小学心理调试与发展手册">
        <development-handbook></development-handbook>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import school from '@/assets/luluo/school.png'
import TextBookCard from '@/views/school/book/TextBookCard.vue'
import TeachingReferenceCard from '@/views/school/book/TeachingReferenceCard.vue'
import JournalPsychology from '@/views/school/book/JournalPsychology.vue'
import DevelopmentHandbook from '@/views/school/book/DevelopmentHandbook.vue'

export default {
  name: 'TextBookPage',
  components: { DevelopmentHandbook, JournalPsychology, TeachingReferenceCard, TextBookCard },
  data () {
    return {
      school
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__nav {
  display: flex;
  justify-content: space-between;
  float: none;
}

::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 60px;
  font-size: 20px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  font-weight: 500;
  color: #303133;
  position: relative;
  cursor: pointer;

  &:hover {
    color: #3D404B !important;
    font-weight: 600;
  }
}

img {
  width: 60px;
  height: 60px;
}

::v-deep .is-active {
  font-weight: 600;
  color: #3D404B !important;
  background: linear-gradient(207deg, #FFDECA 0%, #FFF4EE 100%) !important;
  border-right-color: #DCDFE6;
  border-left-color: #DCDFE6;

}

::v-deep .el-tabs__header {
  background-color: #FFFFFF !important;
}
</style>
