<template>
  <div class="schoolActive">
    <el-card class="box-card">
      <div slot="header" class="title">
        <span>名师风采</span>
      </div>
      <div class="content">
        <div v-for="o in 4" :key="o" class="text item">
          <img src="https://img95.699pic.com/photo/50112/4032.jpg_wh860.jpg">
          <p>优秀教师</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'MasterDemeanor'
}
</script>

<style lang="scss" scoped>
.schoolActive {
  width: 550px;
  .content{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
    img{
      width: 202px;
      height: 130px;
    }
  }
  .title {
    font-size: 24px;
    color: #0962be;
    font-weight: 600;
    height: 49px;
    background: url("@/assets/gtj2023_title-underline.png") no-repeat;
    background-position: 0px 44px;
  }

  ::v-deep .el-card__header {
    padding: 18px 20px;
    border-bottom: 0px;
  }
  ::v-deep .el-card__body {
    padding: 20px;
    padding-bottom: 13px;
  }
}
</style>
