
const teacherRouter = {
  path: 'teacher',
  name: '教师',
  component: () => import('@/views/teacher/index.vue'),
  redirect: '/teacher/courseTeaching',
  children: [{
    path: 'courseTeaching',
    name: 'courseTeaching',
    component: () => import( '@/views/teacher/CourseTeachingLayout.vue'),
    redirect: '/teacher/courseTeaching/design',
    children: [{
      path: 'design',
      name: 'design',
      component: () => import( '@/views/teacher/page/InstructionalDesignPage.vue'),
    },
      {
        path: 'course',
        name: 'course',
        component: () => import( '@/views/teacher/page/ExcellentCoursesPage.vue'),
      }, {
        path: 'materials',
        name: 'materials',
        component: () => import( '@/views/teacher/page/TeachingMaterialsPage.vue'),
      }]
  },
    {
      path: 'actives',
      name: 'actives',
      component: () => import( '@/views/teacher/page/ActivitiesPage.vue'),
    },
    {
      path: 'reading',
      name: 'reading',
      component: () => import('@/views/teacher/page/TeacherReadingPage.vue'),
    }]
}
export default teacherRouter
