<template>
  <div class="app-container">
    <div><header-com/></div>
    <div><nav-item/></div>
    <div><router-view/></div>
    <div><footer-page/></div>
  </div>
</template>

<script>
import HeaderCom from '@/layout/header/HeaderPage.vue'
import NavItem from '@/layout/nav/NavPage.vue'
import FooterPage from '@/layout/footer/FooterPage.vue'

export default {
  name: 'LayoutCom',
  components: { FooterPage, NavItem, HeaderCom }
}
</script>

<style lang="scss" scoped>

</style>
