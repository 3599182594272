<template>
  <div class="app-footer">
    <div class="footer-content">
      <ul class="footer-image">
        <li @click="showWebIntroduce=true" ><a><img :src="webUrl" alt="">
          <p>网站介绍</p></a></li>
        <li @click="showDilog=true"><img :src="annoUrl" alt="">
          <p>免责声明</p></li>
        <li><img :src="phoneUrl" alt="">
          <p>联系我们</p></li>
        <li @mouseenter="showImg=true"  @mouseleave="showImg=false"><img :src="wechatUrl" alt="">
          <p>心育服务号</p>
        </li>
        <li><img :src="erweima" alt="" class="erweima" v-show="showImg">
        </li>
      </ul>

    </div>
    <div class="right">
      <p>邮箱&nbsp;&nbsp;&nbsp;<b>hnszxxljk@163.com</b></p>
      <p>服务与支持电话&nbsp;&nbsp;&nbsp;<b>0371-61657710/7707/7720</b></p>
      <a target="_blank" href="https://beian.miit.gov.cn/"><p>河南省中小学心理健康教育网主办&nbsp;&nbsp;&nbsp;<b>豫ICP备14006179号-3</b></p></a>
      <p>Copyright © 2014-2028 河南省中小学心理健康教育网(http://www.zxxxljk.com)版权所有 All Rights Reserved.</p>
    </div>
    <statement-page :dialogVisible.sync="showDilog"/>
    <web-introduce-page :webIntroduceVisible.sync="showWebIntroduce"></web-introduce-page>
  </div>
</template>

<script>
import anno from '@/assets/footer-icon-annon.png'
import phone from '@/assets/footer-icon-phone.png'
import web from '@/assets/footer-icon-web.png'
import wechat from '@/assets/footer-icon-wechat.png'
import erweima from '@/assets/xhxy_erweima.jpg'
import StatementPage from '@/layout/footer/StatementPage.vue'
import WebIntroducePage from '@/layout/footer/WebIntroducePage.vue'

export default {
  name: 'FooterPage',

  components: {
    WebIntroducePage,
    StatementPage
  },
  data: function () {
    return {
      annoUrl: anno,
      phoneUrl: phone,
      webUrl: web,
      wechatUrl: wechat,
      erweima: erweima,
      showDilog: false,
      showWebIntroduce:false,
      showImg: false
    }
  }
}
</script>

<style lang="scss" scoped>
.app-footer {
  height: 300px;
  background-color: #014586;
  color: white;
  margin-top: 70px;

  .footer-image {
    display: flex;
    justify-content: center;

    li {
      width: 200px;
      height: 100px;
      padding-top: 40px;
      text-align: center;

      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
        // margin-left: 84px;
      }
      .erweima{
        width: 150px;
        height: 150px;

      }
      p {
        font-size: 16px;
        margin-top: 12px;
      }
    }
  }

  .right {
    text-align: center;
    margin-top: 12px;
    line-height: 1.5;
  }
}

</style>
