<template>
  <div class="lastnew">
    <div class="content">
      <el-card class="box-card" shadow="always">
        <div v-for="(item,index) in newsList" :key="index" class="item">
          <router-link :to="{name:'newsDetail', query: {id:item.id}}" target="_blank">
            <div class="newItem">
              <div>
                <span class="icon el-icon-tickets"></span>
                <span>{{ cutString(item.title, 48) }}</span>
              </div>
              <span id="time">{{ parseTime(item.createDate, '{y}-{m}-{d}') }}</span></div>
          </router-link>
          <el-divider></el-divider>
        </div>
        <div>
          <router-link to="/consulting/lastnews" target="_blank">
          <span class="el-icon-more more">更多</span>
          </router-link>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { listNews, getNews } from '@/api/consulte/news'
import { cutString } from '@/utils/ruoyi'

export default {
  name: 'LastNewsItem',
  data () {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 11
      },
      newsList: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /** 查询资讯新闻列表 */
    getList () {
      this.loading = true
      listNews(this.queryParams).then(response => {
        this.newsList = response.rows
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.lastnew {
  width: 556px;
  height: 500px;

  .box-card div {
    font-size: 17px;
    color: #333;
    line-height: 37px;
  }

  .newItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    :hover {
      cursor: pointer;
    }
  }

  ::v-deep .el-divider--horizontal {
    height: 1px;
    width: 100%;
    margin: 2px 0;
  }

  .el-card {
    border: 0px solid #EBEEF5;
  }

  ::v-deep .el-card__body {
    padding: 0;
  }

  #time {
    color: #999999;
  }

  .item :hover {
    background-color: #dfe0e2;
    color: black;
    font-weight: 600;
  }

}

 .more {
   padding-left: 20px;
   &:hover{
     cursor: pointer;
     font-weight: 600;
   }
}
</style>
