import { render, staticRenderFns } from "./ArticleCenter.vue?vue&type=template&id=44cda4ab&scoped=true"
import script from "./ArticleCenter.vue?vue&type=script&lang=js"
export * from "./ArticleCenter.vue?vue&type=script&lang=js"
import style0 from "./ArticleCenter.vue?vue&type=style&index=0&id=44cda4ab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44cda4ab",
  null
  
)

export default component.exports