<template>
  <div class="quickWindow">
    <div class="item">
      <el-card class="card">
        <div slot="header" class="title">
          <div class="list-title">学校</div>
          <div class="list-more"><a href="">更多 +</a></div>
        </div>
        <div class="content school">
          <ul>
            <li>
              <router-link to="/school/psychometrics">
                <img :src="ceping" alt="">
                <p>心理测评</p>
              </router-link>
            </li>
            <li>
              <router-link to="/school/attire">
                <img :src="shebei" alt="">
                <p>心理设备</p>
              </router-link>
            </li>
            <li>
              <a href=""> <img :src="jiaocai" alt="">
                <p>教材教参</p>
              </a>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
    <div class="item">
      <el-card class="card">
        <div slot="header" class="title">
          <div class="list-title">教师</div>
          <div class="list-more"><a href="">更多 +</a></div>
        </div>
        <div class="content teacher">
          <ul>
            <li>
              <a href=""><img :src="teach" alt="">
                <p>课程教学</p>
              </a>
            </li>
            <li>
              <a href=""><img :src="active" alt="">
                <p>活动案例</p>
              </a>
            </li>
            <li>
              <a href=""><img :src="read" alt="">
                <p>教师阅读</p>
              </a>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
    <div class="item">
      <el-card class="card">
        <div slot="header" class="title">
          <div class="list-title">家长</div>
          <div class="list-more"><a href="">更多 +</a></div>
        </div>
        <div class="content parent">
          <ul>
            <li>
              <a href=""><img :src="parentRead" alt="">
                <p>家长阅读</p>
              </a>
            </li>
            <li>
              <a href=""><img :src="parentTeach" alt="">
                <p>家长课堂</p>
              </a>
            </li>
            <li>
              <a href=""><img :src="phone" alt="">
                <p>咨询热线</p>
              </a>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import ceping from '@/assets/quick/ceping.png'
import shebei from '@/assets/quick/shebei.png'
import jiaocai from '@/assets/quick/jiaocai.png'
import teach from '@/assets/quick/teach.png'
import active from '@/assets/quick/active.png'
import read from '@/assets/quick/read.png'
import parentRead from '@/assets/quick/parentRead.png'
import parentTeach from '@/assets/quick/parentTeach.png'
import phone from '@/assets/quick/phone.png'

export default {
  name: 'QuickWindow',
  data: function () {
    return {
      ceping,
      shebei,
      jiaocai,
      teach,
      active,
      read,
      parentRead,
      parentTeach,
      phone
    }
  }
}
</script>

<style lang="scss" scoped>
.quickWindow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .item {
    width: 380px;
    height: 360px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      .list-title {
        height: 36px;
        font-size: 20px;
        font-weight: bold;
        color: #066BB9;
        border-bottom: 3px solid #4490CA;
      }

      .list-more {
        width: 87px;
        height: 22px;
        line-height: 22px;
        background: #066BB9;
        border-radius: 8px;
        text-align: center;
        color: #FFF;
      }
    }
  }

  ::v-deep .el-card__header {
    padding: 9px 20px;
    border-bottom: 1px solid #9D9D9D;
    box-sizing: border-box;
  }

  li {
    text-align: center;
    margin: auto;
    margin-bottom: 10px;
    box-shadow: 0px 4px 14px 0px rgba(34, 87, 179, 0.1);
    border-radius: 10px;
    border: 1px solid #E1EEFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;

    a {
      display: flex;
      height: 80px;
      justify-content: space-evenly;
      align-items: center;
    }

  }

  .school li {
    color: #6D4A41;
    background: linear-gradient(45deg, rgba(255, 151, 70, 0) 0%, rgba(255, 145, 68, 0) 18%, rgba(254, 118, 59, 0.2) 100%);
  }

  .teacher li {
    color: #45597D;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(57, 174, 255, 0.2) 100%);
  }

  .parent li {
    color: #45597D;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(21, 204, 143, 0.2) 100%);
  }
}

img {
  width: 60px;
  height: 60px;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* 绑定抖动动画 */
li:hover {
  animation: shake 0.5s;
}

</style>
