import SchoolLayout from '@/views/school/index.vue'
import PsychoMetrics from '@/views/school/page/PsychoMetrics.vue'
import AttirePageList from '@/views/school/page/AttirePageList.vue'
import SepcialSchool from '@/views/school/page/SepcialSchool.vue'
import TextBookPage from '@/views/school/page/TextBookPage.vue'

const schoolRouter = {
  path: 'school',
  name: 'school',
  component: () => import('@/views/school/index.vue'),
  redirect: '/school/psychometrics',
  children: [{
    path: 'psychometrics',
    name: 'psychometrics',
    component: PsychoMetrics
  },
  {
    path: 'attire',
    name: 'attire',
    component: () => import('@/views/school/page/AttirePageList.vue'),
  },
  {
    path: 'sepcialSchool',
    name: 'sepcialSchool',
    component: () => import('@/views/school/page/SepcialSchool.vue'),
  },
    {
      path: 'experienceSchool',
      name: 'experienceSchool',
      component: () => import('@/views/school/page/SchoolExperience.vue'),
    },
  {
    path: 'textBook',
    name: 'textBook',
    component: () => import('@/views/school/page/TextBookPage.vue'),
  }]
}
export default schoolRouter
