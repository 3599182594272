<template>
  <div>
    <el-dialog title="网站免责声明" :visible="dialogVisible" width="60%" @close="close" :center="true">
      <div class="content">
        <p>
          1、河南省中小学心理健康教育网所载的文字图片等稿件均出于为公众传播有益资讯信息之目的，并不意味着赞同其观点或证实其内容的真实性，我们不对其科学性、严肃性等作任何形式的保证。如其他媒体、网络或个人从本网下载使用须自负版权等法律责任。</p>
        <p> 2、本网站内凡注明
          “来源：河南省中小学心理健康教育网”的所有文字、图片、音频、视频稿件均属本网站原创内容，版权均属河南省中小学心理健康教育网，任何媒体、网站或个人未经本网站协议授权（需要提供《授权协议》）不得转载、链接、转贴或以其他方式复制发表。已经本网协议授权的媒体、网站，在下载使用时必须注明“稿件来源：河南省中小学心理健康教育网”，违者本网将依法追究责任。
        </p>
        <p>
          3、凡本网站转载的所有的文章、图片、音频、视频文件等资料的版权归版权所有人所有，本站采用的非本站原创文章及图片等内容无法一一和版权者联系，如果本网所选内容的文章作者及编辑认为其作品不宜上网供大家浏览，或不应无偿使用（涉及费用问题，需要删除“不宜上网供大家浏览，或不应无偿使用”）请及时用电子邮件或电话通知我们，以迅速采取适当措施，避免给双方造成不必要的经济损失。
        </p>
        <p> 4、对于已经授权本站独家使用并提供给本站资料的版权所有人的文章、图片等资料，如需转载使用，需取得本站和版权所有人的同意。
        </p>
        <p> 5、凡以任何方式登陆本网站或直接、间接使用本网站资料者，视为自愿接受本网站声明的约束。
        </p>
        <p> 6、本网站如无意中侵犯了某个媒体或个人的知识产权，请来信或来电告之，本网站将立即删除。
        </p>
        <p> 7、在本网站注册的用户，需提供您的个人资料，包括但不限于姓名、出生日期、性别、联系地址、电子邮件地址、电话号码等。
          在未经本人同意及确认之前，本网站对您的个人资料负有保密义务。除政府机关依照法定程序要求本网站披露您的个人资料时，本网站将根据执法单位之要求或为公共安全之目的，提供个人资料。在此情况下的个人信息披露，本网站免责。</p>
        <p> 8、用户在本网站上发表评论，即授权本网站与其关联企业在世界范围内的任何媒体上享有非排他、永久、不可更改及完全的许可权利去使用、重新加工、修改、采用、出版、翻译、派生、分发及播出该评论。
        </p>
        <p> 9、本网站上的链接服务可以直接进入其他站点，链接的网站不受本网站的控制，本网站对任何与本站链接网站的内容不负责任。
        </p>
        <p> 10、本声明的生效、履行、解释及争议的解决均适用中华人民共和国法律。未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。
        </p>
        <p> 11、如就本声明内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，则争议各方均可向网站注册所在地，具有管辖权的人民法院提起诉讼。
        </p>
        <p> 12、本《声明》的一切解释权与修改权归河南省中小学心理健康教育网所有。
        </p>
        <p class="contact"> 本网站联系方式：0371-66683822/23
        </p>

      </div>
      <div class="button"> <el-button type="primary" @click="close">关闭</el-button></div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'StatementPage',
  data () {
    return {}
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  text-align: left;
  p{
    font-size: 18px;
    font-weight: 400;
    margin-top: 12px;
  }
  .contact{
    text-align: center;
  }
}

div.button{
  text-align: center;
  margin-top: 20px;
}
 ::v-deep .el-dialog__title{
   font-size: 25px;
  font-weight: 700;
}
</style>
