import request from '@/utils/request'

// 查询资讯新闻列表
export function listNews(query) {
  return request({
    url: '/consulte/news/list',
    method: 'get',
    params: query
  })
}

// 查询资讯新闻详细
export function getNews(id) {
  return request({
    url: '/consulte/news/' + id,
    method: 'get'
  })
}

// 新增资讯新闻
export function addNews(data) {
  return request({
    url: '/consulte/news',
    method: 'post',
    data: data
  })
}

// 修改资讯新闻
export function updateNews(data) {
  return request({
    url: '/consulte/news',
    method: 'put',
    data: data
  })
}

// 删除资讯新闻
export function delNews(id) {
  return request({
    url: '/consulte/news/' + id,
    method: 'delete'
  })
}
