<template>
<div class="app-header" >
  <div class="logo">
    <img :src=logoUrl>
  </div>
<!--  <div class="search">-->
<!--    <el-input-->
<!--      placeholder="请输入检索的内容"-->
<!--      prefix-icon="el-icon-search"-->
<!--      clearable>-->
<!--      <template slot="append">搜索</template>-->
<!--    </el-input>-->
<!--  </div>-->
</div>
</template>

<script>
import logo from '@/assets/logo.png'
export default {
  name: 'HeaderCom',
  data () {
    return {
      logoUrl: logo,
    }
  }
}
</script>

<style lang="scss">

.app-header{
  height: 160px;
  width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo{
    width: 1000px;
    height: 150px;
    padding-left: 50px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .el-input-group__append, .el-input-group__prepend{
    background-color:#355e92!important;
    color: aliceblue;
  }

}
</style>
