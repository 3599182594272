import { render, staticRenderFns } from "./PolicyItem.vue?vue&type=template&id=1833bb8b&scoped=true"
import script from "./PolicyItem.vue?vue&type=script&lang=js"
export * from "./PolicyItem.vue?vue&type=script&lang=js"
import style0 from "./PolicyItem.vue?vue&type=style&index=0&id=1833bb8b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1833bb8b",
  null
  
)

export default component.exports