

const policyDetail = {
  path: '/policyDetail',
  name: 'policyDetail',
  component: () => import('@/views/detail/PolicyDetaiPage'),
  hidden: true
}
const noticeDetail = {
  path: '/noticeDetail',
  name: 'noticeDetail',
  component: () => import('@/views/detail/NoticeDetaiPage.vue'),
  hidden: true
}
const newsDetail = {
  path: '/newsDetail',
  name: 'newsDetail',
  component: () => import('@/views/detail/NewsDetaiPage.vue'),
  hidden: true
}

const attireDetail = {
  path: '/attireDetail',
  name: 'attireDetail',
  component: () => import('@/views/detail/AttireDetaiPage.vue'),
  hidden: true
}
const schoolDetail = {
  path: '/schoolDetail',
  name: 'schoolDetail',
  component: () => import('@/views/detail/SchoolDetaiPage.vue'),
  hidden: true
}
const bookDetail = {
  path: '/bookDetail',
  name: 'bookDetail',
  component: () => import('@/views/detail/BookDetaiPage.vue'),
  hidden: true
}
const designDetail = {
  path: '/designDetail',
  name: 'designDetail',
  component: () => import('@/views/detail/DesignDetaiPage.vue'),
  hidden: true
}
const courseDetail = {
  path: '/courseDetail',
  name: 'courseDetail',
  component: () => import('@/views/detail/CourseDetaiPage.vue'),
  hidden: true
}

const activeDetail = {
  path: '/activeDetail',
  name: 'activeDetail',
  component: () => import('@/views/detail/ActiveDetaiPage.vue'),
  hidden: true
}
const teacherArticleDetail = {
  path: '/teacherArticleDetail',
  name: 'teacherArticleDetail',
  component: () => import('@/views/detail/TeacherArticleDetaiPage'),
  hidden: true
}
const parentCourseDetail = {
  path: '/parentCourseDetail',
  name: 'parentCourseDetail',
  component: () => import('@/views/detail/ParentCourseDetaiPage'),
  hidden: true
}
const parentArticleDetail = {
  path: '/parentArticleDetail',
  name: 'parentArticleDetail',
  component: () => import('@/views/detail/ParentArticleDetaiPage'),
  hidden: true
}
const rotationDetail = {
  path: '/rotationDetail',
  name: 'rotationDetail',
  component: () => import('@/views/detail/RotationDetaiPage'),
  hidden: true
}
const experienceDetail = {
  path: '/experienceDetail',
  name: 'experienceDetail',
  component: () => import('@/views/detail/ExperienceDetaiPage.vue'),
  hidden: true
}

const detailRoute = [policyDetail,
  noticeDetail,
  newsDetail,
  attireDetail,
  schoolDetail,
  bookDetail,
  designDetail,
  courseDetail,
  activeDetail,
  teacherArticleDetail,
  parentCourseDetail,
  parentArticleDetail,
  rotationDetail,
  experienceDetail]
export {
  detailRoute
}
