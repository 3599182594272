import request from '@/utils/request'

// 查询学校经验交流列表
export function listExperience(query) {
  return request({
    url: '/school/experience/list',
    method: 'get',
    params: query
  })
}

// 查询学校经验交流详细
export function getExperience(id) {
  return request({
    url: '/school/experience/' + id,
    method: 'get'
  })
}

// 新增学校经验交流
export function addExperience(data) {
  return request({
    url: '/school/experience',
    method: 'post',
    data: data
  })
}

// 修改学校经验交流
export function updateExperience(data) {
  return request({
    url: '/school/experience',
    method: 'put',
    data: data
  })
}

// 删除学校经验交流
export function delExperience(id) {
  return request({
    url: '/school/experience/' + id,
    method: 'delete'
  })
}
