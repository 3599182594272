<template>
  <div class="app-home">
    <!-- banner1，轮播图和实事资讯-->
    <div class="bannerFirsrt">
      <CarouselItem></CarouselItem>
      <ConsuleteCenter></ConsuleteCenter>
    </div>
    <div class="bannerSecond">
      <course-teach></course-teach>
      <ArticleCenter></ArticleCenter>
    </div>
    <!-- 家长课堂，快捷导航-->
    <div class="bannerThree" >
     <parent-course></parent-course>
      <quick-module></quick-module>
    </div>
    <!-- 心育特色校-->
    <div class="specialSchool">
      <special-school></special-school>
    </div>
  </div>
</template>

<script>
import CarouselItem from '@/views/home/components/CarouselItem.vue'
// import LastNewsItem from '@/views/home/components/TeacherArticleItem.vue'
import ConsuleteCenter from '@/views/home/components/ConsuleteCenter.vue'
import SchoolActive from '@/views/home/components/SchoolActive.vue'
import MasterDemeanor from '@/views/home/components/MasterDemeanor.vue'
import QuickModule from '@/views/home/components/QuickModule.vue'
import SpecialSchool from '@/views/home/components/SpecialSchool.vue'
import QuickWindow from '@/views/home/components/QuickWindow.vue'
import CourseTeach from '@/views/home/components/CourseTeach.vue'
import ArticleCenter from '@/views/home/components/ArticleCenter.vue'
import ParentCourse from '@/views/home/components/ParentCourse.vue'
import PdfPreview from '@/components/PdfPreview/PdfPreview.vue'



export default {
  name: 'homePage',
  components: {
    PdfPreview,
    ParentCourse,
    ArticleCenter,
    CourseTeach,
    QuickWindow,
    SpecialSchool,
    QuickModule,
    MasterDemeanor,
    SchoolActive,
    ConsuleteCenter,
    // LastNewsItem,
    CarouselItem
  }
}
</script>

<style lang="scss" scoped>
.app-home {
  width: 1600px;
  margin: 20px auto 0 auto;

  .bannerFirsrt {
    display: flex;
    justify-content: space-between;
  }

  .bannerSecond {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .bannerThree{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}
</style>
