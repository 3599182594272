<template>
  <div class="psychometrics list-right">
    <div class="bread">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>心理测评</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="linkList">
      <ul>
        <a href="https://www.lvluoxinli.com/hnxhXsjzEducationPc/index.html" target="_blank">
          <li><img :src="jioayu">
            <span>教育局登录</span></li>
        </a>
        <a href="https://www.lvluoxinli.com/hnxhTeacherPC20/index.html" target="_blank">
          <li><img :src="school">
            <span>学校登录</span></li>
        </a>
        <a href="https://www.lvluoxinli.com/hnxhStuPC/index.html" target="_blank">
          <li><img :src="student">
            <span>学生登录</span></li>
        </a>

      </ul>
    </div>
  </div>
</template>

<script>
import jioayu from '@/assets/luluo/jioayuju.png'
import school from '@/assets/luluo/school.png'
import student from '@/assets/luluo/student.png'

export default {
  name: 'PsychoMetrics',
  data () {
    return {
      jioayu,
      school,
      student
    }
  }
}
</script>

<style lang="scss" scoped>

.linkList {
  margin-top: 20px;

  ul {
    display: flex;
    justify-content: space-evenly;
  }

  li {
    position: relative;
    width: 260px;
    height: 320px;
    border-radius: 10px;
    text-align: center;

    img {
      position: absolute;
      width: 200px;
      height: 150px;
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
    }

    span {
      position: absolute;
      display: block;
      font-size: 20px;
      font-weight: 600;
      color: #45597D;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%)
    }
  }

}

ul a:nth-child(1) li {
  background-color: #fff4ea;
}

ul a:nth-child(2) li {
  background-color: #E4F5FF;
}

ul a:nth-child(3) li {
  background-color: #d8f3ea;
}
</style>
