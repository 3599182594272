<template>
  <div class="specialSchool">
    <div class="title">
      学校经验交流
    </div>
    <div class="content">
      <div class="item" v-for="(item,index) in schoolList" :key="index">
        <div>
          <router-link :to="{name:'schoolDetail', query: {id:item.id}}" target="_blank">
          <image-none-preview :src="item.coverUrl" :height="180" :width="376"></image-none-preview>
          </router-link>
        </div>
        <p class="desc">{{ item.schoolName }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import { listSpecial } from '@/api/school/special/special'
import ImageNonePreview from '@/components/ImagePreview/ImageNonePreview.vue'

export default {
  name: 'SpecialSchool',
  components: { ImageNonePreview },
  data () {
    return {
      schoolList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 4
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      listSpecial(this.queryParams).then(response => {
        this.schoolList = response.rows
        this.total = response.total
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.specialSchool {
  background: #fff;
  margin-top: 40px;

}

.title {
  font-size: 24px;
  color: #0962be;
  font-weight: 600;
  height: 49px;
  padding-top: 2px;
  background: url("@/assets/gtj2023_title-underline.png") no-repeat;
  background-position: 0px 44px;
}

.content {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 10px;

  .item {
    img {
      height: 296px;
      width: 376px;
    }

    .desc {
      font-size: 24px;
      font-weight: 600;
      margin-top: 5px;
    }
  }
}

</style>
