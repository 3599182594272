<template>
  <div class="courseTeach">
    <el-card class="box-card">
      <div slot="header" class="title">
        <span>教师课堂</span>
        <router-link :to="{name:'course'}" target="_blank">
          <span>更多</span>
        </router-link>
      </div>
      <div class="primary">
        <div class="courselevel">小学</div>
        <div class="content">
          <div v-for="(item,index) in primaryCourseList" :key="index" class="text item">
            <router-link :to="{name:'courseDetail', query: {id:item.id}}" target="_blank">
              <image-none-preview :src="item.courseCover" :height="130" :width="220"></image-none-preview>
            </router-link>
          </div>
        </div>
      </div>
      <div class="middle ">
        <div class="courselevel">初中</div>
        <div class="content">
          <div v-for="(item,index) in mideleCourseList" :key="index" class="text item">
            <router-link :to="{name:'courseDetail', query: {id:item.id}}" target="_blank">
              <image-none-preview :src="item.courseCover" :height="130" :width="220"></image-none-preview>
            </router-link>
          </div>
        </div>
      </div>
      <div class="high">
        <div class="courselevel">高中</div>
        <div class="content">
          <div v-for="(item,index) in highCourseList" :key="index" class="text item">
            <router-link :to="{name:'courseDetail', query: {id:item.id}}" target="_blank">
              <image-none-preview :src="item.courseCover" :height="130" :width="220"></image-none-preview>
            </router-link>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { listCourse } from '@/api/teacher/courseteach/course'
import ImageNonePreview from '@/components/ImagePreview/ImageNonePreview.vue'

export default {
  name: 'CourseTeach',
  components: { ImageNonePreview },
  data () {
    return {
      primaryCourseList: [],
      mideleCourseList: [],
      highCourseList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 4,
        courseLevel: 1
      },
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      listCourse(this.queryParams).then(response => {
        this.primaryCourseList = response.rows
      })
      this.queryParams.courseLevel=2
      listCourse(this.queryParams).then(response => {
        this.mideleCourseList = response.rows
      })
      this.queryParams.courseLevel=3
      listCourse(this.queryParams).then(response => {
        this.highCourseList = response.rows
      })
      this.loading = false
    },
  }

}
</script>

<style lang="scss" scoped>
.courseTeach {
  width: 990px;

  .content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
  }

  .title {
    font-size: 24px;
    color: #0962be;
    font-weight: 600;
    height: 49px;
    background: url("@/assets/gtj2023_title-underline.png") no-repeat;
    background-position: 0px 44px;
    display: flex;
    justify-content: space-between;
  }

  ::v-deep .el-card__header {
    padding: 18px 20px 0px 20px;
    border-bottom: 0px;
  }

  ::v-deep .el-card__body {
    padding: 20px;
    padding-bottom: 13px;
  }
}

.courselevel {
  color: #45597D;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
  margin-left: 10px;
}
</style>
