import request from '@/utils/request'

// 查询心理设备列表
export function listAttire(query) {
  return request({
    url: '/school/attire/list',
    method: 'get',
    params: query
  })
}

// 查询心理设备详细
export function getAttire(id) {
  return request({
    url: '/school/attire/' + id,
    method: 'get'
  })
}

// 新增心理设备
export function addAttire(data) {
  return request({
    url: '/school/attire',
    method: 'post',
    data: data
  })
}

// 修改心理设备
export function updateAttire(data) {
  return request({
    url: '/school/attire',
    method: 'put',
    data: data
  })
}

// 删除心理设备
export function delAttire(id) {
  return request({
    url: '/school/attire/' + id,
    method: 'delete'
  })
}
