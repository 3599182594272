<template>
  <div class="quick">
    <el-card class="box-card">
      <div slot="header" class="title">
        <span>快捷导航</span>
      </div>
      <div class="content">
        <ul>
          <li>
            <router-link to="/school/psychometrics">
              <i class="el-icon-postcard"/>
              <p>心理测评</p>
            </router-link>
          </li>
<!--          <li>-->
<!--            <a href="http://122.114.184.107:8085/" target='_blank'>-->
<!--              <i class="el-icon-notebook-2"/>-->
<!--              <p>杂志订阅</p>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a href="http://122.114.184.107:8082/xszs/202212"><i class="el-icon-search"></i>-->
<!--              <p>证书查询</p>-->
<!--            </a>-->
<!--          </li>-->
          <li>
            <a href="http://122.114.92.23:8085/" target='_blank'>
              <i class="el-icon-position"/>
              <p>在线投稿</p>
            </a>
          </li>
          <li>
            <router-link :to="{name:'textBook'}" target="_blank">
              <i class="el-icon-tickets"/>
              <p>教材教参</p>
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'design'}" target="_blank">
              <i class="el-icon-notebook-2"/>
              <p>教学设计</p>
            </router-link>
          </li>
          <li>
            <a href="http://www.100875.com.cn/" target='_blank'>
              <i class="el-icon-s-platform"/>
              <p>基础教育教材</p>
            </a>
          </li>
          <li>
            <a href="https://dfjc.100875.com.cn/" target='_blank'>
              <i class="el-icon-s-platform"/>
              <p>地方教材平台</p>
            </a>
          </li>
<!--          <li>-->
<!--            <a href="https://dfjc.100875.com.cn/" target='_blank'>-->
<!--              <i class="el-icon-reading"/>-->
<!--              <p>证书下载</p>-->
<!--            </a>-->
<!--          </li>-->
        </ul>

      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'QuickModule'
}
</script>

<style lang="scss" scoped>
.quick {
  width: 556px;

  .content {
    ul{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      text-align: center;
    }

    li {
      height: 56px;
      width: 220px;
      border: 1px solid #c5c8cb;
      line-height: 78px;
      cursor: pointer;
      border-radius: 5px;
      margin-bottom: 14px;
      background-color: #e7f1fd;

      :hover {
        background-color: #0962be;

        i {
          color: black;
        }
      }

      a {
        display: flex;
        text-align: center;
        height: 100%;

        i {
          margin-top: 10px;
          margin-left: 20px;
          font-size: 40px;
          color: #355e92;
        }

        p {
          line-height: 40px;
          height: 40px;
          width: 140px;
          margin-top: 10px;
          text-align: left;
          letter-spacing: 2px;
          font-size: 21px;

        }
      }
    }

  }

  .title {
    font-size: 24px;
    color: #0962be;
    font-weight: 600;
    height: 49px;
    background: url("@/assets/gtj2023_title-underline.png") no-repeat;
    background-position: 0px 44px;
  }

  ::v-deep .el-card__header {
    padding: 18px  20px 0 20px;
    border-bottom: 0px;
  }

  ::v-deep .el-card__body {
    padding: 20px;
    padding-bottom: 13px;
  }

}
</style>
