<template>
  <div class="teacherReferenceCard">
    <el-card class="box-card">
      <div class="list">
        <div v-for="item in bookList" :key="item.title" class="textitem">
          <div class="hl-top">
            <div class="hl-left">
              <image-preview :src="item.bookCover" :height="180" :width="255"></image-preview>
            </div>
            <div class="hl-right">
              <router-link :to="{name:'bookDetail', query: {id:item.id}}" target="_blank">
                <div class="hl-title">{{ item.bookName }}</div>
                <div class="hl-txt"><span>{{ item.introduction }}</span></div>
              </router-link>
            </div>
          </div>
          <div class="hl-bottom"><span>时间:&nbsp;&nbsp;{{ parseTime(item.createDate, '{y}-{m}-{d}') }}</span></div>
        </div>
      </div>
      <div class="page">
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { listBook } from '@/api/school/book/book'

export default {
  name: 'TeachingReferenceCard',
  data () {
    return {
      total: 0,
      bookList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 5,
        bookType: 2
      },
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      listBook(this.queryParams).then(response => {
        this.bookList = response.rows
        this.total = response.total
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.grade, .version {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  .desc {
    margin-right: 40px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
  }
}

.textitem {
  width: 98%;
  border-bottom: 1px dashed #666666;
  margin-bottom: 10px;
  padding: 16px;
  padding-bottom: 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px 0px rgba(34, 87, 179, 0.1);
  border-radius: 10px;
  border: 1px solid #E1EEFF;

}

.hl-top {
  display: flex;
}

.hl-left {
  margin-right: 30px;
}


.hl-title {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 34px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.hl-txt {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-indent: 24px;
  margin-bottom: 10px;
}

.hl-bottom span {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 24px;

}

.hl-bottom {
  text-align: right;
}

//::v-deep .el-tabs--border-card {
//  background: #FFF;
//  border: 0px;
//  box-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
//}

::v-deep .el-card {
  border: 0px solid #EBEEF5;
}

.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
  box-shadow: none;
}

.page {
  text-align: center;
  margin-top: 30px;

  ::v-deep .number.active {
    background-color: #337ab7 !important;
  }
}
</style>
