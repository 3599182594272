<template>
  <div class="schoolActive">
    <el-card class="box-card">
      <div slot="header" class="title">
        <span>学校经验交流</span>
      </div>
      <div v-for="(item,index) in experienceList" :key="index" class="text item">
        <router-link :to="{name:'experienceDetail', query: {id:item.id}}" target="_blank">
        <div class="content">
          <div>
            <span>{{ item.title }}</span>
          </div>
          <span id="time">{{ parseTime(item.createDate, '{y}-{m}-{d}') }}</span></div>
        </router-link>
        <el-divider></el-divider>
      </div>
    </el-card>
  </div>
</template>

<script>
import { listExperience } from '@/api/school/experience/experience'

export default {
  name: 'SchoolActive',
  data () {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 学校经验交流表格数据
      experienceList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 7
      },
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /** 查询学校经验交流列表 */
    getList () {
      this.loading = true
      listExperience(this.queryParams).then(response => {
        this.experienceList = response.rows
        this.total = response.total
        this.loading = false
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.schoolActive {
  width: 550px;

  .title {
    font-size: 24px;
    color: #0962be;
    font-weight: 600;
    height: 49px;
    background: url("@/assets/gtj2023_title-underline.png") no-repeat;
    background-position: 0px 44px;
  }

  ::v-deep .el-card__header {
    padding: 18px 20px;
    border-bottom: 0px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    color: #333;
    line-height: 38px;
    padding-left: 50px;
    background: url("@/assets/gtj2023_icon_fangtan.png") no-repeat left center;

    .icon {
      margin-right: 10px;
    }
  }

  ::v-deep .el-divider--horizontal {
    height: 1px;
    width: 100%;
    margin: 2px 0;
  }

  #time {
    color: #999999;
  }

  .item :hover {
    background-color: #dfe0e2;
    color: black;
    font-weight: 600;
  }
}
</style>
