
const consulteRouter = {
  path: 'consulting',
  name: 'consulting',
  component: () => import('@/views/consulting/index.vue'),
  redirect: '/consulting/lastnews',
  children: [{
    path: 'policy',
    name: 'policy',
    component: () => import('@/views/consulting/policy/PolicyListPage.vue'),
  },
    {
      path: 'notice',
      name: 'notice',
      component: () => import( '@/views/consulting/notice/NoticeListPage.vue'),
    },
    {
      path: 'lastnews',
      name: 'lastnews',
      component: () => import(  '@/views/consulting/lastnews/LastListPage.vue'),
    }
  ]
}
export default consulteRouter
