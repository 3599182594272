import Vue from 'vue'
import {
  Button, Select, Input, Menu, MenuItem, MenuItemGroup,Tag,
  Submenu, Icon, Dialog, Carousel, CarouselItem, Card, Divider, Tabs, TabPane, Row, Col,
  Backtop, Breadcrumb, BreadcrumbItem, Pagination, CheckboxGroup, Checkbox, Table, TableColumn,
  RadioGroup, Radio, RadioButton, Form, FormItem, InputNumber, Image,Option,MessageBox,Message,Notification
  ,Loading
} from 'element-ui'


Vue.use(Tag)
Vue.use(Option)
Vue.use(Image)
Vue.use(InputNumber)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(Button)
Vue.use(Select)
Vue.use(Input)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(Divider)
Vue.use(TabPane)
Vue.use(Backtop)
Vue.use(Row)
Vue.use(Col)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Pagination)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Table)
Vue.use(TableColumn)

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$loading = Loading;
