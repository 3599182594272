<!--资讯中心tab页面-->
<template>
  <div class="center">
    <el-tabs v-model="activeName"  >
      <el-tab-pane label="最新资讯" name="first" lazy>
        <div class="news">
          <last-news-item></last-news-item>
        </div>
      </el-tab-pane>
      <el-tab-pane label="公告通知" name="second" lazy>
        <div class="notice">
         <notice-item></notice-item>
        </div>
      </el-tab-pane>
      <el-tab-pane label="政策文件" name="three" lazy>
        <div class="policy">
          <policy-item></policy-item>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LastNewsItem from '@/views/home/components/consulute/LastNewsItem.vue'
import PolicyItem from '@/views/home/components/consulute/PolicyItem.vue'
import NoticeItem from '@/views/home/components/consulute/NoticeItem.vue'

export default {
  name: 'ConsuleteCenter',
  components: { NoticeItem, PolicyItem, LastNewsItem },
  data () {
    return {
      activeName: 'first'
    }
  }
}
</script>

<style lang="scss" scoped>
.center {
  width: 556px;
  height: 556px;

  ::v-deep .el-tabs__item {
    font-size: 24px;
    font-weight: 600;
    height: 51px;
    margin-bottom: 8px;

  }

  ::v-deep .el-tabs__item.is-active {
    color: #0962be;
  }

  ::v-deep .el-tabs__nav-wrap::after {
    width: 70%;
    height: 4px;
    background-color: #E4E7ED;
    z-index: 1;
  }

  ::v-deep .el-tabs__active-bar {
    height: 4px;
    background-color: #0962be;
  }

}
</style>
