import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import HomePage from '@/views/home/HomePage.vue'
import schoolRouter from '@/router/school/school'
import teacherRouter from '@/router/teacher/teacher'
import consulteRouter from '@/router/consulte/consulte'
import parentRouter from '@/router/parent/parent'
import { detailRoute } from '@/router/detail'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'layout',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomePage
      }, consulteRouter, schoolRouter, teacherRouter, parentRouter]
  }
]
routes.push(...detailRoute)

let routerPush = VueRouter.prototype.push
// push,防止路由点击报错
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  routes
})

// 防止连续点击多次路由报错

export default router
