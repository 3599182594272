import request from '@/utils/request'

// 查询心理特色学校列表
export function listSpecial(query) {
  return request({
    url: '/school/special/list',
    method: 'get',
    params: query
  })
}

// 查询心理特色学校详细
export function getSpecial(id) {
  return request({
    url: '/school/special/' + id,
    method: 'get'
  })
}

// 新增心理特色学校
export function addSpecial(data) {
  return request({
    url: '/school/special',
    method: 'post',
    data: data
  })
}

// 修改心理特色学校
export function updateSpecial(data) {
  return request({
    url: '/school/special',
    method: 'put',
    data: data
  })
}

// 删除心理特色学校
export function delSpecial(id) {
  return request({
    url: '/school/special/' + id,
    method: 'delete'
  })
}
